// Generated by Framer (d0574d7)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, Link, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./DZpbyE8Bf-0.js";

const cycleOrder = ["j_qrm9auW", "vNH4P1WRp"];

const serializationHash = "framer-qROaF"

const variantClassNames = {j_qrm9auW: "framer-v-qs5zzt", vNH4P1WRp: "framer-v-180js0e"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 2.05, ease: [0.12, 0.23, 0.5, 1], type: "tween"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "j_qrm9auW", "Variant 2": "vNH4P1WRp"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, GElVwnO6M: image ?? props.GElVwnO6M ?? {src: "https://framerusercontent.com/images/MOw18SCarah70BiQCLXOBsghEqE.png"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "j_qrm9auW"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, GElVwnO6M, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "j_qrm9auW", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter7w4992 = activeVariantCallback(async (...args) => {
setVariant("vNH4P1WRp")
})

const onMouseLeave1hoilfg = activeVariantCallback(async (...args) => {
setVariant("j_qrm9auW")
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-qROaF", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link {...addPropertyOverrides({vNH4P1WRp: {href: "https://www.instagram.com/designstudionextgen.eu"}}, baseVariant, gestureVariant)}><Image {...restProps} background={{alt: "", fit: "fill", intrinsicHeight: 360, intrinsicWidth: 360, pixelHeight: 360, pixelWidth: 360, sizes: "min(32px, 100vw)", ...toResponsiveImage(GElVwnO6M)}} className={`${cx("framer-qs5zzt", className)} framer-kc56ol`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"j_qrm9auW"} onMouseEnter={onMouseEnter7w4992} ref={ref ?? ref1} style={{filter: "none", WebkitFilter: "none", ...style}} variants={{vNH4P1WRp: {filter: "hue-rotate(360deg)", WebkitFilter: "hue-rotate(360deg)"}}} {...addPropertyOverrides({vNH4P1WRp: {"data-framer-name": "Variant 2", as: "a", onMouseLeave: onMouseLeave1hoilfg}}, baseVariant, gestureVariant)}/></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-qROaF [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qROaF .framer-kc56ol { display: block; }", ".framer-qROaF .framer-qs5zzt { height: 32px; overflow: visible; position: relative; width: 32px; }", ".framer-qROaF.framer-v-180js0e .framer-qs5zzt { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); text-decoration: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"vNH4P1WRp":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"GElVwnO6M":"image"}
 * @framerImmutableVariables false
 */
const FramerDZpbyE8Bf: React.ComponentType<Props> = withCSS(Component, css, "framer-qROaF") as typeof Component;
export default FramerDZpbyE8Bf;

FramerDZpbyE8Bf.displayName = "j";

FramerDZpbyE8Bf.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerDZpbyE8Bf, {variant: {options: ["j_qrm9auW", "vNH4P1WRp"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, GElVwnO6M: {__defaultAssetReference: "data:framer/asset-reference,MOw18SCarah70BiQCLXOBsghEqE.png?originalFilename=pngtree-instagram-icon-png-image_6315974.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerDZpbyE8Bf, [])